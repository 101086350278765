ol,
li {
  margin: 0;
  padding: 0;
}

.tableContainer {
  background-color: #f5f5f5;
  padding: 25px;
  overflow-x: hidden;
  margin-top: 0px;
  font-family: "Open Sans", sans-serif;
}

.table {
  display: flex;
  justify-content: space-between;
  /*width: 590px;*/
  width: 1571px;
  transition: 500ms all ease-in-out;
  list-style: none;
}

.arrows {
  display: flex;
  margin-bottom: 12px;
  cursor: pointer;

}

@media (max-width:700px) {
  .arrows {
    display: none;
  }
}

@media (min-width:700px) {
  .tableContainer {
    padding: 12px;
  }
}

.arrow-left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  background-color: white;

}

.arrow-left img {
  width: 100px;
  transform: rotate(180deg);
}

.arrow-right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  margin-left: 6px;
  background-color: white;
}

.arrow-right img {
  width: 100px;

}

.table .column {
  max-width: 190px;
}

.table .row {
  background-color: white;
  margin-bottom: 4px;
  padding: 12.5px;
}

.row.row-rank {
  display: flex;
  justify-content: flex-end;
  font-weight: 700;
  font-size: 23px;
  color: rgba(128, 128, 128, 0.411);
  padding: 5px 15px;
  margin-bottom: 0px;
  position: relative;
}

.row.row-rank span.badge {
  font-size: 12px;
  font-weight: 500;
  background-color: #219cd8;
  position: absolute;
  color: white;
  padding: 3.5px 6.5px;
  left: 0px;
  top: 8px;
  border-bottom-right-radius: 5px;
}

.row.row-rank span.badge.red {
  background-color: #ff8304;
}

.row.row-rank span.badge.green {
  background-color: #26a69a;
}

.row.row-image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  position: sticky;
  top: 0;
}

.row.row-image img {
  height: 80px;
  width: 120px;
  object-fit: cover;
}

.row.row-title {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: #0298d5;
  margin-bottom: 0px;
  min-height: 63px;
}

.row.row-title a {
  text-decoration: none;
  color: inherit;
}

.row.row-testsiegel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row.row-testsiegel img {
  width: 90%;
}

.row.row-ratings .stars {
  color: #00aeff;
  font-size: 12px;
  text-align: center;
}

.row.row-ratings span {
  display: block;
  text-align: center;
  font-size: 11px;
  margin-top: 5px;
}

.row.row-spec label {
  font-weight: 700;
  line-height: 20px;
  font-size: 12px;
  padding-bottom: 3px;
  display: block;
  text-align: center;
}

.row.row-spec span {
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  display: block;
  text-align: center;
}

.row.row-spec.certs {
  min-height: 73px;
}

.row.row-spec.certs .certs-imgs {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row.row-spec.certs .certs-imgs img {
  width: 25px;
  margin-right: 5px;
}

.row.row-spec.yt i {
  display: block;
  text-align: center;
  font-size: 20px;
  color: red;
}

.row.row-spec .yes {
  color: rgb(114, 168, 31);
}

.row.row-spec .no {
  color: rgb(168, 31, 31);
}

label abbr {
  border-bottom: 1px dotted #777;
  color: rgb(36, 112, 148);
}

.__react_component_tooltip {
  font-size: 11px;
  font-weight: 400;
}

.row.row-buy {
  background-color: #4f8098;
}

.row.row-buy ol,
ul {
  list-style: none;
}

.row.row-buy label {
  font-weight: 700;
  line-height: 20px;
  font-size: 12px;
  padding-bottom: 0px;
  display: block;
  text-align: center;
  color: white;
}

.row.row-buy .btn-buy {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 700;
  align-items: center;
  box-shadow: 0px 3px 0 0 #e8e8e8ad;
  margin-top: 10px;
}

.btn-buy span span.versand {
  display: block;
  font-size: 8px;
  opacity: 0.7;
}

.btn-buy .shop-img {
  flex-basis: 50%;
}

.shop-img img {
  width: 100%;
}

.floatingButtons {
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 35px;
  background: white;
  width: 100%;
  box-shadow: 2px 2px 20px rgb(0 0 0 / 20%);
}

.floatingButtons.hidden {
  display: none;
}

.floatingButtons .inside {
  display: flex;
  justify-content: space-between;
}

.floatingButtons .inside .item {
  flex-basis: 47%;
}

.floatingButtons .inside span {
  font-size: 12px;
  margin-bottom: 5px;
  display: block;
  text-align: center;
  font-weight: 600;
}

.floatingButtons .inside a {
  background-color: #219cd8;
  display: block;
  color: white;
  text-align: center;
  font-size: 15px;
  padding: 7px 13px;
}