* {
  box-sizing: border-box;
}

body {
  background-color: rgb(233, 233, 233);
}

.app {
  max-width: 700px;
  margin: auto;
  background-color: white;
}

body {
  font-family: -apple-system, "Roboto", sans-serif !important;
}

a {
  color: inherit;
  text-decoration: none;
}

.intro {
  margin: 30px 25px 40px 25px;
}

h1 {
  font-size: 27px;
  font-weight: 700;
  margin-bottom: 0px;
}

p {
  font-size: 14px;
  line-height: 160%;
  color: #464646;
}

a.link {
  color: #219cd8;
  text-decoration: underline;
}

/*
.visitors {
  font-weight: 600;
  font-size: 17px;
  opacity: 0.6;
}
*/

.visitors {
  font-weight: 600;
  font-size: 14px;
  opacity: 0.6;
  text-transform: uppercase;
}

.visitors i {
  margin-right: 5px;
}

.presse-before {
  text-align: center;
  display: block;
  font-size: 9px;
  font-weight: 600;
  line-height: 180%;
  letter-spacing: 0.3px;
  opacity: 0.6;
  background-color: rgb(231, 231, 231);
  margin-top: -9px;
}

.mainContent .inside {
  padding: 25px;
}

.mainContent img.img {
  margin: 10px 0px 10px 0px;
}

h2 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0px;
}

h3 {
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 0px;
}

/* modal Style */

.youtubeModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.914);
  z-index: 999;
}

@keyframes slideIn {
  0% {
    transform: translateX(-500px) translateY(100%);
  }

  100% {
    transform: translateX(0px) translateY(100%);
  }
}

.youtubeModal .inside {
  width: 95%;
  height: 100%;
  margin: auto;
  backdrop-filter: blur(3px);
}

.youtubeModal .inside iframe {
  margin: auto;
  width: 100%;
  height: 250px;
  transform: translateY(100%);
  animation: slideIn 800ms ease-in-out;
}

.youtubeModal.hidden {
  display: none;
}

.youtubeModal .close {
  position: absolute;
  top: 190px;
  right: 20px;
  color: white;
  font-weight: 600;
  font-size: 160%;
}

.testbericht {
  padding-bottom: 10px;
  z-index: 90;
}

.back {
  opacity: 0.7;
  font-size: 14px;
}

.back i {
  margin-right: 4px;
}

.testbericht img {
  margin: 25px 0px 0px 0px;
  width: 100%;
}

.testbericht img.no-cap {
  margin: 25px 0px;
}

.testbericht figcaption {
  font-size: 11px;
  opacity: 0.7;
  margin-bottom: 15px;
}

.testbericht .buy-cta {
  display: block;
  background-color: #2271b5;
  padding: 15px;
  color: white;
  text-align: center;
  font-weight: 700;
  font-size: 15px;
  margin-top: 20px;
}

.testbericht p {
  margin-bottom: 0px;
}

.floatingCTA {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 14px;
  background: white;
  box-shadow: 0px 1px 30px 0px rgb(0 0 0 / 20%);
}

.floatingCTA.hidden {
  display: none;
}

.floatingCTA a.buy-cta {
  margin-top: 0px;
  display: flex;
  justify-content: space-around;
}

.cta-price {
  opacity: 0.7;
}

/* SHARE BUTTONS */

.shareButtons {
  background-color: white;
  display: inline-block;
  /* padding: 15px; */
  display: inline-flex;
  justify-content: space-between;
  margin-bottom: 25px;
  align-items: baseline;
}

.shareButtons .shareLabel {
  padding: 15px;
  font-size: 11px;
  width: 165px;
  font-weight: 700;
}

.shareButtons .buttons {
  display: flex;
  align-items: baseline;
}

.shareButtons .buttons .icon {
  padding: 15px 20px;
  border-left: 1px solid #e2e2e2;
}

.productFilter {
  margin: 25px;
}

.productFilter .filter {
  background-color: #eeeeeedb;
  padding: 15px;
}

.filter h3 {
  margin: 0px;
  line-height: 175%;
}

hr {
  border-top: 1px solid #dbdbdb;
  border-left: none;
}

.accordion * {

  outline: none;
}

.accordion *:focus {
  outline: none;
}

.accordion__item {
  margin-bottom: 5px;
}

.accordion__heading {
  padding: 15px;
  background-color: #e4e4e4a1;
  font-weight: 600;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.775);
}

.accordion__panel {

  padding-left: 15px;
}

.accordion__panel p a {
  text-decoration: underline;
  color: #1271a1;
  font-weight: 700;
}