.header {
  /*background-color: #068bc0;
  background-color: #219cd8;*/
  background-color: #2b333a;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .left {
  flex-basis: 230px;
  margin-left: 15px;
}

.header .left img {
  width: 100%;
  transform: translateY(3px);
}

.header .right {
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #219cd8;
  font-size: 29px;
}

.menu {
  margin: 0;
  background-color: #414c57;
  color: rgba(255, 255, 255, 0);
  transition: 200ms all ease-in-out;
  height: 0px;
  z-index: -1;
  position: relative;
}

.menu.open {
  height: 215px;
  z-index: 1;
  color: rgba(255, 255, 255, 1);
}

.menu ul {
  padding: 25px;
  margin: 0;
  list-style: none;
  line-height: 225%;
  font-size: 18px;
}

.menu ul li:first-child {
  opacity: 0.65;
  font-size: 13px;
  margin-bottom: 5px;
}
